import React from "react";

const Impressum = () => {
  return (
    <footer className="footer-mf bg-dark pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h5 className="text-light">Impressum</h5>
            <p className="text-light">
              Maximilian von Beck
              <br />
              Hinter den Pinneichen 25
              <br />
              32756 Detmold
              <br />
              Telefon: +49 179 1218721
              <br />
              E-Mail: kontakt@maximilianvonbeck.de
              <br />
              <br />
              Haftungsausschluss: Die Inhalte dieser Webseite wurden mit
              größter Sorgfalt erstellt. Ich übernehme jedoch keine Gewähr
              für die Vollständigkeit, Richtigkeit und Aktualität der
              bereitgestellten Informationen.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Impressum;
