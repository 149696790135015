import React from "react";
import "./stars.scss";
import Typed from "react-typed";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

class Intro extends React.Component {
  render() {
    return (
      <div id="home" className="intro route bg-image background">
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />

        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="intro-title mb-4">Hallo, Ich bin Maximilian</h1>
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
                <strong className="text-slider">
                  <Typed
                    strings={[
                      "Frontend Entwickler",
                      "Backend Entwickler",
                      "System Administrator",
                      "Tech Enthusiast",
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
                </strong>
              </p>
              <p className="intro-description pt-3">
                Ich liebe es, kreative Lösungen zu entwickeln und komplexe Probleme zu lösen. Mit einem Hintergrund in der Webentwicklung und Netzwerkadministration bringe ich technisches Know-how und eine Leidenschaft für Technologie mit.
              </p>
              <p className="pt-3">
                <a
                  className="btn btn-primary btn js-scroll px-4"
                  href="#work"
                  role="button"
                >
                  Portfolio
                </a>
                <a
                  className="btn btn-secondary btn js-scroll px-4 ml-2"
                  href="#contact"
                  role="button"
                >
                  Kontakt
                </a>
              </p>
              <div className="social-links pt-3">
                <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" className="social-icon" title="GitHub">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a href="https://www.linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer" className="social-icon" title="LinkedIn">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
